
import { useParams,useLocation } from "react-router-dom";
import { useEffect } from 'react';
export default function ScrollToTop({ activePage }) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname,activePage ]);

  return null;
}
