// setup.js
const dealerSettings = {
    'dealer123': {
      filterType: 'modal',
    },
    'dealer456': {
      filterType: 'offcanvas',
    },
  };
  
  module.exports = dealerSettings;
  