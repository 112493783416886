import React, {
    useState,
    useEffect,
    lazy,
    Suspense,
    useRef,
    useCallback,
} from "react";


import { Button, Card, Stack, ButtonGroup } from "react-bootstrap";

import { HashRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import { FaFilter } from "react-icons/fa";
import SortOptions from "../utils/sortCard/sortCard";

const CarDetails = lazy(() => import("./CarsDetails"));
const SearchBar = lazy(() => import("./SearchBar"));
const Dropdownfilter = lazy(() => import("../filter/Dropdownfilter"));

const SearchSuggestions = lazy(() => import("./SearchSuggestions"));
const SeparateFilterDisplay = lazy(() => import("./SeparateFilterDisplay"));
import { getPropertyOptions } from "../utils/CarProperties";
import { searchMatchesForCar } from "../utils/SearchMatches";
import {
    handleSearchInputChange,
    handleSuggestionClick,
} from "../utils/SearchUtils";
// import { fetchData } from "../utils/fetch";
import * as sortalg from "../utils/SortData";
import "../scss/index.scss";

import Filtering from "../filter/Filter";
import CarList from "../carList/CarList";
import { dotStream } from "ldrs";

function App({ fetchFunction }) {
    const [show, setShow] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const dealerId = "dealer123"; // Sæt dette til den aktuelle dealer's ID
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({
        type: [],
        make: [],
        model: [],
        year: [],
        mileage: [],
        price: [],
        geartype: [],
        pricetype: [],
        propellant: [],
        EquipmentList: [],
    });

    const [loading, setLoading] = useState(true);
    const [appliedFiltersCount] = useState(0);
    const [selectedFiltersHistory, setSelectedFiltersHistory] = useState([]);
    const [sortOrder, setSortOrder] = useState("Default");
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
    const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);

    const carsContainerRef = useRef(null);
    const [searchValue, setSearchValue] = useState("");
    const [searchSuggestions, setSearchSuggestions] = useState([]);
    const [appliedFilters, setAppliedFilters] = useState({});
    const [displayedAppliedFilters, setDisplayedAppliedFilters] = useState([]);
    const [displayedSearchKeyword, setDisplayedSearchKeyword] = useState([]);
    const [searchKeywords, setSearchKeywords] = useState([]);
    const [
        displayedSearchKeywordsWithoutKey,
        setDisplayedSearchKeywordsWithoutKey,
    ] = useState([]);
    dotStream.register();
    useEffect(() => {
        if (isOffcanvasOpen) {
            // Gør noget, når offcanvas åbnes, fx låse baggrunden
            document.body.style.overflow = "hidden";
        } else {
            // Gør noget andet, når offcanvas lukkes, fx låse baggrunden op
            document.body.style.overflow = "auto";
        }
    }, [isOffcanvasOpen]);

    const fetchDataAndSetData = async () => {
        try {
            const jsonData = await fetchFunction();
            setData(jsonData);
            setFilteredData(jsonData);
    
            // Calculate min-max values for year, mileage, and price
            const minYear = Math.min(...jsonData.map(car => car.Year));
            const maxYear = Math.max(...jsonData.map(car => car.Year));
            const minMileage = Math.min(...jsonData.map(car => car.Mileage));
            const maxMileage = Math.max(...jsonData.map(car => car.Mileage));
            const minPrice = Math.min(...jsonData.map(car => car.Price));
            const maxPrice = Math.max(...jsonData.map(car => car.Price));
    
            // Update selectedFilters state with min-max values
            setSelectedFilters(prevFilters => ({
                ...prevFilters,
                year: [minYear, maxYear],
                mileage: [minMileage, maxMileage],
                price: [minPrice, maxPrice],
            }));
    
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };
    
    useEffect(() => {
        fetchDataAndSetData();
    }, []);

    useEffect(() => {
        filterAndSortData();
    }, [data, selectedFilters, sortOrder, searchValue]);
    const propertyOptions = getPropertyOptions(data);
    useEffect(() => {
        if (isFilterModalOpen) {
            document.body.classList.add("modal-open");
        } else {
            document.body.classList.remove("modal-open");
        }
    }, [isFilterModalOpen]);

    const handleSearchInputChangeWrapper = (e) => {
        handleSearchInputChange(
            e,
            setSearchKeywords,
            setSearchValue,
            setSearchSuggestions,
            propertyOptions,
            setSearchKeywords
        );
    };

    const handleSuggestionClickWrapper = (suggestion) => {
        handleSuggestionClick(
            suggestion,

            setSearchValue,
            setSearchSuggestions,
            setDisplayedSearchKeyword,
            setSearchKeywords,

            data,
            setSelectedFilters,
            filterAndSortData
        );
    };

    useEffect(() => {
        window.onscroll = scrollEffFunc;
        return () => {
            window.onscroll = null;
        };
    }, []);

    const filterAndSortData = () => {
        const filteredCars = data.filter((car) => {
            const isYearInRange =
                car.Year >= selectedFilters.year[0] &&
                car.Year <= selectedFilters.year[1];
            const isMileageInRange =
                car.Mileage >= selectedFilters.mileage[0] &&
                car.Mileage <= selectedFilters.mileage[1];
            const isPriceInRange =
                car.Price >= selectedFilters.price[0] &&
                car.Price <= selectedFilters.price[1];
            const searchMatches = searchMatchesForCar(
                car,
                displayedSearchKeyword
            );
            
            return (
                (selectedFilters.type.length === 0 ||
                    selectedFilters.type.includes(car.Type)) &&
                (selectedFilters.make.length === 0 ||
                    selectedFilters.make.includes(car.Make)) &&
                (selectedFilters.model.length === 0 ||
                    selectedFilters.model.includes(car.Model)) &&
                (selectedFilters.geartype.length === 0 ||
                    selectedFilters.geartype.includes(car.GearType)) &&
                (selectedFilters.pricetype.length === 0 ||
                    selectedFilters.pricetype.includes(car.PriceType)) &&
                (selectedFilters.propellant.length === 0 ||
                    selectedFilters.propellant.includes(car.Propellant)) &&
                    (selectedFilters.EquipmentList.length === 0 ||
                        Object.values(car.EquipmentList).some((equipmentArray) =>
                          selectedFilters.EquipmentList.some((equipment) =>
                            equipmentArray.includes(equipment)
                          )
                        )) &&
                isYearInRange &&
                isMileageInRange &&
                isPriceInRange &&
                searchMatches
            );
        });

        const sorted = sortData(filteredCars, sortOrder);
        setFilteredData(sorted);
    };

    const handleFilterChange = useCallback(
        (filterType, values) => {
            setSelectedFilters((prevFilters) => ({
                ...prevFilters,
                [filterType]: values,
            }));

            setAppliedFilters((prevAppliedFilters) => ({
                ...prevAppliedFilters,
                [filterType]: values,
            }));
            setDisplayedAppliedFilters((prevAppliedFilters) => [
                ...prevAppliedFilters,
                { filterType, values },
            ]);
            setSelectedFiltersHistory([
                ...selectedFiltersHistory,
                { ...selectedFilters },
            ]);
        },
        [selectedFilters, selectedFiltersHistory]
    );

    const sortData = (data, sortOrder) => sortalg.sortData(data, sortOrder);
    const closeFilter = () => {
        setIsFilterModalOpen(false);
    };

    const scrollEffFunc = () => {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop;
        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight;
        const scrolled = (winScroll / height) * 100;
        const scrollElement = document.getElementById("scrollingE");
        if (scrollElement) {
            scrollElement.style.width = scrolled + "%";
        }
    };
    if (loading) {
        return (
          <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
            <l-dot-stream size="60" speed="2.5" color="black" text="Loading"></l-dot-stream>
          </div>
        );
    }
    return (
        <>
        
            <Router>
                <ScrollToTop />
                <Routes>
                    <Route
                        exact
                        path="/car/:id"
                        element={
                            <Suspense
                                fallback={
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{ minHeight: "100vh" }}
                                    >
                                        <l-dot-stream
                                            size="60"
                                            speed="2.5"
                                            color="black"
                                            text="Loading"
                                        ></l-dot-stream>
                                    </div>
                                }
                            >
                                <CarDetails cars={data} />
                            </Suspense>
                        }
                    />
                    <Route
                        exact
                        path="/"
                        element={
                            <>
                                <Suspense
                                    fallback={
                                        <div
                                            className="d-flex justify-content-center align-items-center"
                                            style={{ minHeight: "100vh" }}
                                        >
                                            <l-dot-stream
                                                size="60"
                                                speed="2.5"
                                                color="black"
                                                text="Loading"
                                            ></l-dot-stream>
                                        </div>
                                    }
                                >
                                    {/* <Filter
                    data={data}
                    selectedFilters={selectedFilters}
                    onFilterChange={handleFilterChange}
                    appliedFiltersCount={appliedFiltersCount}
                    filteredDataCount={filteredData.length}
                    onClose={closeFilter}
                    showFilter={isFilterModalOpen}
                    appliedFilters={appliedFilters}
                    setAppliedFilters={setAppliedFilters}
                  /> */}
                                    <Card>
                                        <Card.Header>
                                            <Stack
                                                direction="horizontal"
                                                gap={3}
                                            >
                                                <div
                                                    className="me-auto button-group-responsive"
                                                    style={{ width: "100%" }}
                                                >
                                                    <SearchBar
                                                        searchValue={
                                                            searchValue
                                                        }
                                                        handleSearchInputChange={
                                                            handleSearchInputChangeWrapper
                                                        }
                                                    />
                                                    <SearchSuggestions
                                                        searchSuggestions={
                                                            searchSuggestions
                                                        }
                                                        handleSuggestionClick={
                                                            handleSuggestionClickWrapper
                                                        }
                                                    />
                                                </div>
                                                <Suspense
                                                    fallback={
                                                        <div
                                                            className="d-flex justify-content-center align-items-center"
                                                            style={{
                                                                minHeight:
                                                                    "100vh",
                                                            }}
                                                        >
                                                            <l-dot-stream
                                                                size="60"
                                                                speed="2.5"
                                                                color="black"
                                                                text="Loading"
                                                            ></l-dot-stream>
                                                        </div>
                                                    }
                                                >
                                                    <ButtonGroup
                                                        className="button-group-responsive"
                                                        aria-label="First group"
                                                    >
                                                        <Dropdownfilter
                                                            setIsOffcanvasOpen
                                                            dealerId={dealerId}
                                                            data={data}
                                                            selectedFilters={
                                                                selectedFilters
                                                            }
                                                            onFilterChange={
                                                                handleFilterChange
                                                            }
                                                            appliedFiltersCount={
                                                                appliedFiltersCount
                                                            }
                                                            filteredDataCount={
                                                                filteredData.length
                                                            }
                                                            appliedFilters={
                                                                appliedFilters
                                                            }
                                                            setAppliedFilters={
                                                                setAppliedFilters
                                                            }
                                                        />
                                                        <Filtering
                                                            setIsOffcanvasOpen
                                                            dealerId={dealerId}
                                                            data={data}
                                                            selectedFilters={
                                                                selectedFilters
                                                            }
                                                            onFilterChange={
                                                                handleFilterChange
                                                            }
                                                            appliedFiltersCount={
                                                                appliedFiltersCount
                                                            }
                                                            filteredDataCount={
                                                                filteredData.length
                                                            }
                                                            appliedFilters={
                                                                appliedFilters
                                                            }
                                                            setAppliedFilters={
                                                                setAppliedFilters
                                                            }
                                                            loading={loading}
                                                        />
                                                        <SortOptions
                                                            sortOrder={
                                                                sortOrder
                                                            }
                                                            setSortOrder={
                                                                setSortOrder
                                                            }
                                                        />
                                                    </ButtonGroup>
                                                </Suspense>
                                            </Stack>
                                        </Card.Header>

                                        <Card.Body>
                                            <div className="scrollmenu">
                                                <SeparateFilterDisplay
                                                    displayedSearchKeyword={
                                                        displayedSearchKeyword
                                                    }
                                                    appliedFilters={
                                                        appliedFilters
                                                    }
                                                    setAppliedFilters={
                                                        setAppliedFilters
                                                    }
                                                    setSelectedFilters={
                                                        setSelectedFilters
                                                    }
                                                    filterAndSortData={
                                                        filterAndSortData
                                                    }
                                                    setDisplayedSearchKeyword={
                                                        setDisplayedSearchKeyword
                                                    }
                                                    setSearchValue={
                                                        setSearchValue
                                                    }
                                                    setSearchSuggestions={
                                                        setSearchSuggestions
                                                    }
                                                    selectedFilters={
                                                        selectedFilters
                                                    }
                                                    loading={loading} 
                                                />
                                            </div>
                                            <Suspense>
                                                <CarList cars={filteredData} />
                                            </Suspense>
                                        </Card.Body>
                                    </Card>
                                    <div className="main-lux-header">
                                        <div className="header-group">
                                            {/* <button
                        className="lux-button-primary"
                        onClick={() =>
                          setIsFilterModalOpen(!isFilterModalOpen)
                        }>
                        <span className="filter-text">Filtre</span> <FaFilter />
                      </button> */}
                                        </div>

                                        <div className="progress-container">
                                            <div
                                                className="progress-bar"
                                                id="scrollingE"
                                                style={{
                                                    width: "0%",
                                                    height: "5px",
                                                    backgroundColor: "black",
                                                }}
                                            />
                                        </div>

                                        <div></div>
                                    </div>
                                    <div
                                        className={`main-content ${
                                            show ? "content-shifted" : ""
                                        }`}
                                    ></div>
                                </Suspense>
                            </>
                        }
                    />
                </Routes>
            </Router>
        </>
    );
}
export default App;