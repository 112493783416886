import React, {
  useState,
  useEffect,
  lazy,
  Suspense,
} from "react";

import {
  Container,
  Card
} from "react-bootstrap";
import { HashRouter as Router, Route, Routes } from "react-router-dom";

import CarList2 from '../carList/CarList2';
import Category from '../carList/category';
function App2({ fetchFunction }) {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const fetchDataAndSetData = async () => {
    try {
      const jsonData = await fetchFunction();
      setData(jsonData);
      
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDataAndSetData();
  }, []);
  return (
    <>
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <>
                <Suspense fallback={<div>Loading...</div>}>
                  
              
                <CarList2 cars={data.slice(0, 6)} />

                   {/* <Category cars={data} /> */}
                </Suspense>
              </>
            }
          />
        </Routes>
      </Router>
    </>
  );
}
export default App2;