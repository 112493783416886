export const formatCurrency = (value) => {
    const number = parseFloat(value);
    const roundedNumber = Math.round(number);
    return !isNaN(roundedNumber)
      ? roundedNumber.toLocaleString('da-DK', { style: 'currency', currency: 'DKK', minimumFractionDigits: 0, maximumFractionDigits: 0 })
      : '';
  };

  export const formatNumber = (value) => {
    const number = parseFloat(value);
    const roundedNumber = Math.round(number);
    return !isNaN(roundedNumber)
      ? roundedNumber.toLocaleString('da-DK', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
      : '';
  };


  
  export const parseDate =(dateString) => {
    const parts = dateString.split(' '); // Splitter datostrengen i dato og klokkeslett
    const dateParts = parts[0].split('-'); // Splitter datoen i dag, måned og år
    const timeParts = parts[1].split(':'); // Splitter klokkeslettet i timer, minutter og sekunder
    const year = parseInt(dateParts[2], 10); // Henter ut årstallet og konverterer til heltall
    const month = parseInt(dateParts[1], 10) - 1; // Henter ut måneden og konverterer til heltall (måneder er null-indeksert i JavaScript)
    const day = parseInt(dateParts[0], 10); // Henter ut dagen og konverterer til heltall
    const hours = parseInt(timeParts[0], 10); // Henter ut timene og konverterer til heltall
    const minutes = parseInt(timeParts[1], 10); // Henter ut minuttene og konverterer til heltall
    const seconds = parseInt(timeParts[2], 10); // Henter ut sekundene og konverterer til heltall
  
    // Oppretter et nytt Date-objekt med de hentede verdiene
    return new Date(year, month, day, hours, minutes, seconds);
  };