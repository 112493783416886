import React, { useState } from "react";
import { FaRegSquarePlus, FaRegSquareMinus } from "react-icons/fa6";
import { parseDate } from "./basis";
import "./accordion.css";

import { Form,Button } from "react-bootstrap";
export const generateCheckboxFilter = (
  filterType,
  uniqueValues,
  selectedFilters,
  handleCheckboxChange,
  includeSearch = true,
  headerName
) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleAccordionToggle = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const filteredValues = includeSearch
    ? uniqueValues.filter((option) =>
        option.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : uniqueValues;

  // Use headerName if provided, else capitalize and use filterType as the default
  const displayHeaderName =
    headerName || filterType.charAt(0).toUpperCase() + filterType.slice(1);

  return (
    <div className={`accordion-item ${filterType}`}>
      <div className="accordion-header" onClick={handleAccordionToggle}>
        <div className="Lux-h4">{displayHeaderName}</div>
        <div className="accordion-icon">
          {isAccordionOpen ? <FaRegSquareMinus /> : <FaRegSquarePlus />}
        </div>
      </div>
      <div
        className="accordion-body mb-5"
        id={`accordion-body-${filterType}`}
        style={{ display: isAccordionOpen ? "block" : "none" }}>
        {includeSearch && (
      <input
      type="text"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      className="search-input"
      style={{
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/><path fill="none" d="M0 0h24v24H0z"/></svg>')`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '10px center',
        paddingLeft: '40px', // Adjust based on the icon width
      }}
    />
    
        )}
        <div className="checkbox-container">
          {filteredValues.map((option, index) => (
            <div className="checkbox" key={index}>
              <input
                type="checkbox"
                id={`${filterType}-checkbox-${index}`}
                checked={selectedFilters[filterType].includes(option)}
                onChange={(e) =>
                  handleCheckboxChange(filterType, e.target.checked, option)
                }
              />
              <div
                className="Lux-h5"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  document
                    .getElementById(`${filterType}-checkbox-${index}`)
                    .click()
                }>
                {option}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const CheckboxGroupWithStyle = ({
  title,
  options,
  selectedFilters,
  handleCheckboxChange,
}) => {
  return (
    <div
      className="button-checkbox-group-container"
      id={`${title.toLowerCase()}`}>
      <div className="button-checkbox-group-grid">
        {options.map((option, index) => (
          <div key={index} className="button-checkbox-item">
            <input
              type="checkbox"
              id={`${title.toLowerCase()}-checkbox-${index}`}
              className="hidden-checkbox"
              checked={selectedFilters[title.toLowerCase()]?.includes(option)}
              onChange={(e) =>
                handleCheckboxChange(
                  title.toLowerCase(),
                  e.target.checked,
                  option
                )
              }
            />
            <label
              htmlFor={`${title.toLowerCase()}-checkbox-${index}`}
              className={`button-checkbox-label ${
                selectedFilters[title.toLowerCase()]?.includes(option)
                  ? "checked"
                  : ""
              }`}>
              {title.toLowerCase() === "geartype"
                ? mapGearTypeLabel(option)
                : option}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapGearTypeLabel = (geartype) => {
  return geartype === "A"
    ? "Automatic"
    : geartype === "M"
    ? "Manual"
    : geartype;
};

export const generateCombinedCheckboxButtons = (
  filterCategories,
  selectedFilters,
  handleCheckboxChange
) => {
  const allOptions = Object.entries(filterCategories).flatMap(
    ([category, options]) => options.map((option) => ({ category, option }))
  );

  return (
    <div className="check-buttons-list mb-3">
      {allOptions.map(({ category, option }, index) => (
        <span
          key={`${category}-${option}-${index}`}
          className="button-checkbox-option">
          <input
            type="checkbox"
            id={`${category.toLowerCase()}-checkbox-${index}`}
            className="hidden-checkbox"
            checked={selectedFilters[category.toLowerCase()]?.includes(option)}
            onChange={(e) =>
              handleCheckboxChange(
                category.toLowerCase(),
                e.target.checked,
                option
              )
            }
          />
          <label
            htmlFor={`${category.toLowerCase()}-checkbox-${index}`}
            className={`button-checkbox-label ${
              selectedFilters[category.toLowerCase()]?.includes(option)
                ? "checked"
                : ""
            }`}>
            {category.toLowerCase() === "geartype"
              ? mapGearTypeLabel(option)
              : option}
          </label>
        </span>
      ))}
    </div>
  );
};

const sortData = (data, sortOrder) => {
  switch (sortOrder) {
    case "Creation Date (Ascending)":
      return data
        .slice()
        .sort(
          (a, b) =>
            new Date(parseDate(a.CreatedDate)) -
            new Date(parseDate(b.CreatedDate))
        );
    case "Creation Date (Descending)":
      return data
        .slice()
        .sort(
          (a, b) =>
            new Date(parseDate(b.CreatedDate)) -
            new Date(parseDate(a.CreatedDate))
        );
    case "Price (Ascending)":
      return data
        .slice()
        .sort((a, b) => parseFloat(a.Price) - parseFloat(b.Price));
    case "Price (Descending)":
      return data
        .slice()
        .sort((a, b) => parseFloat(b.Price) - parseFloat(a.Price));
    case "Mileage (Ascending)":
      return data
        .slice()
        .sort((a, b) => parseInt(a.Mileage) - parseInt(b.Mileage));
    case "Mileage (Descending)":
      return data
        .slice()
        .sort((a, b) => parseInt(b.Mileage) - parseInt(a.Mileage));
    case "Year (Ascending)":
      return data.slice().sort((a, b) => parseInt(a.Year) - parseInt(b.Year));
    case "Year (Descending)":
      return data.slice().sort((a, b) => parseInt(b.Year) - parseInt(a.Year));
    default:
      return data;
  }
};

export const filteredCars = (data, selectedFilters, sortOrder) => {
  const filteredCars = data.filter((car) => {
    const isYearInRange =
      car.Year >= selectedFilters.year[0] &&
      car.Year <= selectedFilters.year[1];
    const isMileageInRange =
      car.Mileage >= selectedFilters.mileage[0] &&
      car.Mileage <= selectedFilters.mileage[1];
    const isPriceInRange =
      car.Price >= selectedFilters.price[0] &&
      car.Price <= selectedFilters.price[1];

    return (
      (selectedFilters.type.length === 0 ||
        selectedFilters.type.includes(car.Type)) &&
      (selectedFilters.make.length === 0 ||
        selectedFilters.make.includes(car.Make)) &&
      (selectedFilters.model.length === 0 ||
        selectedFilters.model.includes(car.Model)) &&
      (selectedFilters.geartype.length === 0 ||
        selectedFilters.geartype.includes(car.GearType)) &&
      (selectedFilters.pricetype.length === 0 ||
        selectedFilters.pricetype.includes(car.PriceType)) &&
      (selectedFilters.propellant.length === 0 ||
        selectedFilters.propellant.includes(car.Propellant)) &&
      isYearInRange &&
      isMileageInRange &&
      isPriceInRange
    );
  });

  const sorted = sortData(filteredCars, sortOrder);
  return sorted;
};


export const SwitchFilterComponent = ({
  filterType,
  uniqueValues,
  selectedFilters,
  handleCheckboxChange,
  includeSearch = true,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  // Filtrer uniqueValues baseret på søgeterm, hvis søgning er inkluderet
  const filteredValues = includeSearch
    ? uniqueValues.filter(value =>
        value.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : uniqueValues;

  return (
    <div className="switch-row">
      {filteredValues.map((value, index) => (
        <div className="switch-item" key={index}>
          <Form.Check 
            type="switch"
            id={`${filterType}-switch-${index}`}
            label={value}
            checked={selectedFilters[filterType].includes(value)}
            onChange={(e) => handleCheckboxChange(filterType, e.target.checked, value)}
          />
        </div>
      ))}
    </div>
  );
};


export const ToggleButtonGroup = ({
  filterType,
  filteredValues,
  selectedFilters,
  handleCheckboxChange,
}) => {
  // Hjælpefunktion til at tjekke, om en værdi er valgt
  const isSelected = (value) => selectedFilters[filterType].includes(value);

  return (
    <div className="d-flex flex-wrap">
      {filteredValues.map((value, index) => (
        <Button
          key={index}
          variant={isSelected(value) ? "secondary" : "outline-secondary"}
          className="m-1"
          onClick={() => handleCheckboxChange(filterType, !isSelected(value), value)}
        >
          {value}
        </Button>
      ))}
    </div>
  );
};

