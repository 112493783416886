import { parseDate } from "./basis";
import axios from 'axios';
import { sortData } from "./SortData"; // Erstat "dinSorteringsfil" med navnet på din sorteringsfil

const API_URL = `${window.App.url}/api/vehicles_data`;
// console.log(API_URL);

export const fetchData1 = async () => {
    try {
        const response = await axios.get(`${API_URL}`);
        if (!response.data) {
            throw new Error('No data found');
        }
        return response.data['Vehicles'];
    } catch (error) {
        throw error;
    }
};

export const fetchData2 = async () => {
    try {
        const response = await axios.get(`${API_URL}`);
        if (!response.data) {
            throw new Error('No data found');
        }
        const filteredData = response.data['Vehicles'].filter(vehicle => vehicle.PriceType === 'Leasing');
        return filteredData;
    } catch (error) {
        throw error;
    }
};

export const fetchData3 = async () => {
    try {
        const response = await axios.get(`${API_URL}`);
        if (!response.data) {
            throw new Error('No data found');
        }
        const latestVehicles = sortData(response.data['Vehicles'], "CreatedDate (Descending)");
        return latestVehicles;
    } catch (error) {
        throw error;
    }
};

// export const fetchDataCustomer = async () => {
//     try {
//       const response = await fetch(API_URL);
//       if (!response.ok) {
//         throw new Error(`HTTP error! status: ${response.status}`);
//       }
//       const jsonData = await response.json();
//       return jsonData['Vehicles'];
//     } catch (error) {
//       throw error;
//     }
//   };
