import React, { useState } from 'react';
import { Button, OverlayTrigger, Popover, ButtonGroup, ListGroup } from 'react-bootstrap';
import { FaSortUp, FaSortDown } from 'react-icons/fa';
import './sortCard.css';

const SortOptions = ({ sortOrder, setSortOrder }) => {
  const [orderDirection, setOrderDirection] = useState('Ascending');

  const sortCriteria = {
    'Make': 'Mærke',
    'Price': 'Pris',
    'Year': 'Årgang',
    'Mileage': 'Kilometerstand',
    'CreatedDate': 'Oprettelsesdato'
  };

  const handleSelect = (eventKey) => {
    setSortOrder(eventKey + ' (' + orderDirection + ')');
  };

  const toggleOrderDirection = () => {
    const newOrderDirection = orderDirection === 'Ascending' ? 'Descending' : 'Ascending';
    setOrderDirection(newOrderDirection);
    setSortOrder(sortOrder.split(' (')[0] + ' (' + newOrderDirection + ')');
  };

  const popover = (
    <Popover id="popover-sort-options">
      <Popover.Header as="h3">Sorter</Popover.Header>
      <Popover.Body>
        <ButtonGroup 
         size="sm"
         className='button-group-responsive'
       >
          <Button variant={orderDirection === 'Ascending' ? 'secondary' : 'outline-secondary'} onClick={toggleOrderDirection}>
         Stigende
          </Button>
          <Button variant={orderDirection === 'Descending' ? 'secondary' : 'outline-secondary'} onClick={toggleOrderDirection}>
           Faldende
          </Button>
        </ButtonGroup>
        <hr />
        <ListGroup>
          {Object.entries(sortCriteria).map(([key, label]) => (
            <ListGroup.Item action key={key} onClick={() => handleSelect(key)} variant={sortOrder.includes(key) && orderDirection === 'Descending' ? 'secondary' : 'light'}>
              {label}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
      <Button variant="outline-secondary">Sorter</Button>
    </OverlayTrigger>
  );
};

export default SortOptions;
