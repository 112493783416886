// CustomAccordion.js

import React, { useState } from 'react';
import { FaRegSquareMinus, FaRegSquarePlus } from 'react-icons/fa6'; // Assuming you're using FontAwesome for icons

export const CustomAccordion = ({ filterType, renderContent }) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(true); // Start in the open state

  const handleAccordionToggle = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };
  return (
    <div className={`accordion-item ${filterType}`}>
      <div className="accordion-header" onClick={handleAccordionToggle}>
        <div className="Lux-h4">
          {filterType.charAt(0).toUpperCase() + filterType.slice(1)}
        </div>
        <div className="accordion-icon">
          {isAccordionOpen ? <FaRegSquareMinus /> : <FaRegSquarePlus />}
        </div>
      </div>
      <div className="accordion-body" style={{ display: isAccordionOpen ? 'block' : 'none' }}>
        {renderContent()}
      </div>
    </div>
  );
};
