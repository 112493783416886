export const getPropertyOptions = (data) => {
    return [
        { key: 'Type', options: [...new Set(data.map((car) => car.Type))] },
        { key: 'GearType', options: [...new Set(data.map((car) => car.GearType))] },
        
        { key: 'Propellant', options: [...new Set(data.map((car) => car.Propellant))] },
        { key: 'Year', options: [...new Set(data.map((car) => car.Year))]},
        { key: 'Mileage', options: [...new Set(data.map((car) => car.Mileage))]},
        { key: 'Price', options: [...new Set(data.map((car) => car.Price))]},
        { key: 'PurchaseType', options: [...new Set(data.map((car) => car.PriceType))] },
        { key: 'Equipment', options: [...new Set(data.flatMap((car) => Object.values(car.EquipmentList || {}).flat()))] },
        { key: 'Variant' , options: [...new Set(data.map((car) => car.Variant))]},
        { key: 'Model' , options: [...new Set(data.map((car) => car.Model))]},
        { key: 'Make' , options: [...new Set(data.map((car) => car.Make))]},
    ];
};

