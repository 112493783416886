import React, { useState } from 'react';
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";
import { Link, useNavigate } from 'react-router-dom';


export const Carousel = ({ data, carId, car, filteredData }) => {
  const navigate = useNavigate(); // Erstat useHistory med useNavigate i v6
  const [slide, setSlide] = useState(0);
  let clickTimeout = null;

  const handleCarClick = (carId, isDoubleClicked) => {
    if (isDoubleClicked) {
      if (filteredData && filteredData.length > 0) {
        // Hvis der er filtreret data, brug intern navigation
        navigate(`/car/${carId}`, { state: { car, filteredData } });
      } else {
        // Hvis der ikke er filtreret data, brug global URL
        const baseUrl = window.App.url; // Sørg for at dette er korrekt defineret et sted i din app
        const targetUrl = `${baseUrl}/biler#/car/${carId}`;
        window.location.href = targetUrl;
      }
    }
  };

  const handleSlideClick = (e, idx) => {
    e.stopPropagation();
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      clickTimeout = null;
      handleCarClick(carId, true);
    } else {
      clickTimeout = setTimeout(() => {
        clickTimeout = null;
        nextSlide();
      }, 300);
    }
  };

  const nextSlide = () => {
    setSlide(prevSlide => (prevSlide === data.length - 1 ? 0 : prevSlide + 1));
  };

  const prevSlide = () => {
    setSlide(prevSlide => (prevSlide === 0 ? data.length - 1 : prevSlide - 1));
  };

  const SlideContent = ({ item, idx }) => (
    <div onClick={(e) => handleSlideClick(e, idx)} className="slide-container">
      <img src={item.src} alt={item.alt} className={`lux-slide ${slide === idx ? "lux-slide-active" : "slide-hidden"}`} />
    </div>
  );

  return (
    <div className="lux-carousel">
      <div onClick={prevSlide}>
        <BsArrowLeftCircleFill className="arrow arrow-left" />
      </div>
      {data.map((item, idx) => (
        <SlideContent item={item} idx={idx} key={idx} />
      ))}
      <div onClick={nextSlide}>
        <BsArrowRightCircleFill className="arrow arrow-right" />
      </div>
    </div>
  );
};

export default Carousel;
