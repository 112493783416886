export const searchMatchesForCar = (car, displayedSearchKeyword) => {
  const carProperties = Object.keys(car).filter(
     (key) => key !== 'Pictures' && key !== 'PictureCount' && key !== 'ModifiedDate' && key !== 'CreatedDate' && key !== 'Categories' && key !== 'SpecificationId' && key !== 'VehicleId' && key !== 'VehicleSourceId'
  );
  const flattenedEquipmentList = Object.values(car.EquipmentList || {}).flat();
 
  return displayedSearchKeyword.every((keyword) => {
     return carProperties.some((key) => {
       if (key.toLowerCase() === 'equipmentlist') {
         return flattenedEquipmentList.some((item) => item.toLowerCase() === keyword.toLowerCase());
       } else if (typeof car[key] === 'string') {
         const propertyValue = car[key].toLowerCase();
         return propertyValue === keyword.toLowerCase() || `${car.Make} ${car.Model}`.toLowerCase() === keyword.toLowerCase();
       } else if (typeof car[key] === 'number') {
         const numericKeyword = parseFloat(keyword);
         return !isNaN(numericKeyword) && car[key] === numericKeyword;
       }
       return false;
     });
  });
 };
 