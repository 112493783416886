import { parseDate } from "./basis";
const sortData = (data, sortOrder) => {
    switch (sortOrder) {
      case "Make (Ascending)":
        return data.slice().sort((a, b) => {
            if (a.Make < b.Make) return -1;
            if (a.Make > b.Make) return 1;
            return 0;
        });
    case "Make (Descending)":
        return data.slice().sort((a, b) => {
            if (a.Make > b.Make) return -1;
            if (a.Make < b.Make) return 1;
            return 0;
        });

        case "CreatedDate (Ascending)":
          return data.slice().sort((a, b) => {
              const dateA = parseDate(a.CreatedDate);
              const dateB = parseDate(b.CreatedDate);
              if (dateA < dateB) return -1;
              if (dateA > dateB) return 1;
              // Hvis CreatedDate er lik, sorter etter Make
              if (a.Make < b.Make) return -1;
              if (a.Make > b.Make) return 1;
              return 0;
          });
      case "CreatedDate (Descending)":
          return data.slice().sort((a, b) => {
              const dateA = parseDate(a.CreatedDate);
              const dateB = parseDate(b.CreatedDate);
              if (dateA > dateB) return -1;
              if (dateA < dateB) return 1;
              // Hvis CreatedDate er lik, sorter etter Make
              if (a.Make < b.Make) return -1;
              if (a.Make > b.Make) return 1;
              return 0;
          });
      case "Price (Ascending)":
        return data.slice().sort((a, b) => parseFloat(a.Price) - parseFloat(b.Price));
      case "Price (Descending)":
        return data.slice().sort((a, b) => parseFloat(b.Price) - parseFloat(a.Price));
      case "Mileage (Ascending)":
        return data.slice().sort((a, b) => parseInt(a.Mileage) - parseInt(b.Mileage));
      case "Mileage (Descending)":
        return data.slice().sort((a, b) => parseInt(b.Mileage) - parseInt(a.Mileage));
      case "Year (Ascending)":
        return data.slice().sort((a, b) => parseInt(a.Year) - parseInt(b.Year));
      case "Year (Descending)":
        return data.slice().sort((a, b) => parseInt(b.Year) - parseInt(a.Year));
      default:
        return data.slice().sort((a, b) => {
          if (a.Make < b.Make) return -1;
          if (a.Make > b.Make) return 1;
          return 0;
      });
    }
  };
  
  
  export { sortData };